@tailwind base;
@tailwind components;
@tailwind utilities;

.w-textpercent {
    width: 35%;
}

.w-imagepercent {
    width: 60%;
}

.text-xxs {
    font-size: 0.5rem;
}

.w-maxbody {
    width: 300px;
}

.face-textbox {
    top: 0px;
    width: 100%;
    height: 100%;
}

#textbox {
    top: 59%;
    left: 47%;
    width: 31%;
    height: 10.5%;
}

#logo {
    top: 9%;
    left: 5%;
    width: 10%;
    height: auto;
    cursor: pointer;
}

#title {
    top: 18.3%;
    left: 5%;
    height: 6.3%;
    overflow: visible
}

#subheading {
    top: 27.7%;
    left: 5%;
    height: 2.6%;
    overflow: visible
}

#body {
    top: 30%;
    left: 5%;
    height: 2%;
    overflow: visible
}

#copyright {
    top: 45.5%;
    left: 5%;
    height: 1.5%;
    overflow: visible
}

#button {
    top: 85%;
    left: 53%;
    width: 19%;
    height: auto;
    cursor: pointer;
}

#button-div {
    top: 85%;
    left: 53%;
    width: 19%;
    height: auto;
    cursor: pointer;
}

#arrow {
    top: 87.2%;
    left: 68%;
    width: 1.7%;
    height: auto;
    cursor: pointer;
}

#button-text {
    top: 86.3%;
    left: 55.2%;
    height: 3.4%;
    overflow: visible;
    fill: #ffffff;
    cursor: pointer;
}

#button2 {
    top: 85%;
    left: 56%;
    width: 13.3%;
    height: auto;
    cursor: pointer;
}

#button2-div {
    top: 85%;
    left: 53%;
    width: 19%;
    height: auto;
    cursor: pointer;
}

#arrow2 {
    top: 86.8%;
    left: 65.3%;
    width: 1.7%;
    height: auto;
    cursor: pointer;
}

#button2-text {
    top: 86.3%;
    left: 58.3%;
    height: 3.4%;
    overflow: visible;
    fill: #ffffff;
    cursor: pointer;
}

#score-text {
    top: 59%;
    left: 48.5%;
    height: 3.4%;
    overflow: visible;
}

#score {
    top: 70%;
    left: 50%;
    height: 3.4%;
    overflow: visible;
}

input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: #eeeeee00;
    /* vertically align text to top */
    vertical-align: top;
  }

  textarea {
    background-color: #eeeeee00;
    resize: none;
  }

  textarea::placeholder {
    color: rgb(0, 0, 0);
  }

  .no-outline:focus {
    outline: none;
  }